<template>
    <v-main>
      <div class="main-content">
      <Card class="card-custom">
        <template #title>
          <v-row class="header pa-0 align-center flex-column">
            
            <v-col class="column-title">
              
              <div class="column-title__item">
                <back-button />
            </div>
              <div class="column-title__item " style="font-size: 24px; font-weight: bold; ">
                <span>
                {{ $i18n.t('transaction_data') }} 
                    
                  </span>
                
              </div>
              <h2 class="column-title__item " style="font-size: 24px; font-weight: bold; ">
              </h2>
            </v-col>
            
          </v-row>
        </template>
        <template #content>
          <v-overlay v-if="loading" opacity="1" class="custom-overlay" color="transparent" z-index="500">
            <v-progress-circular
                indeterminate
                color="var(--main-flamingo)"
                size="150"
            >
              <span class="font-weight-bold">{{ $i18n.t('loading') }}...</span>
            </v-progress-circular>
          </v-overlay>
          <v-row class="justify-center">
            <v-col xl="6" md="6" sm="12" style="min-width: 250px">
              <Card class="card-custom h-100">
                <template #title>
    
                  <h4>{{ $i18n.t('transaction_details') }}</h4>
                </template>
                <template #content>
    
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $i18n.t('transaction_id') }}:
                    </v-col>
                    <v-col class="text-right">
                      {{ transaction && transaction.transaction_id !== null ? transaction.transaction_id : '---' }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $i18n.t('payment_type') }}:
                    </v-col>
                    <v-col class="text-right">
                      {{ transaction && transaction.payment_sub_type && transaction.payment_sub_type !== null ? translatedPaymentType : '---' }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $t('type') }}:
                    </v-col>
                    <v-col class="text-right">
      
                      {{ transaction && transaction.payment_type && transaction.payment_type !== null ? translatedType : '---' }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $t('amount') }}:
                    </v-col>
                    <v-col class="text-right">
      
                      {{ transaction && transaction.amount ? transaction.amount : '0' }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $i18n.t('date') }}:
                    </v-col>
                    <v-col class="justify-end d-flex align-center">
                      {{ transaction && transaction.date && transaction.date !== null ? transactionDate : '---' }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $i18n.t('method') }}:
                    </v-col>
                    <v-col class="text-right">
                      {{ transaction && transaction.method && transaction.method !== null ? transaction.method : '---' }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $i18n.t('status') }}:
                    </v-col>
                    <v-col class="text-right">
                      <v-chip small label
                      v-if="transaction && transaction.status && transaction.status !== null"
            :color="statusLabelColor"
            style="color: var(--white)"
            >
              {{ transaction && transaction.status && transaction.status !== null ? transactionStatus : '---' }}
            </v-chip>
             
              <span v-else>---</span>
            
                    </v-col>
                  </v-row>
                </template>
              </Card>
            </v-col>
            <v-col xl="6" md="6" sm="12" style="min-width: 250px">
              <Card class="card-custom h-100">
                <template #title>
    
                  <h4>{{ $i18n.t('player_details') }}</h4>
                </template>
                <template #content>
                  <v-row align="center" @click="$router.push(`/player/${player.id}`)" style="cursor: pointer;">
                    <v-col class="text-left">
                      ID:
                    </v-col>
                    <v-col class="text-right link-hover c-main-flamingo">
                      {{ player && player.id !== null ? player.id : '---' }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $i18n.t('total_balance') }}:
                    </v-col>
                    <v-col class="text-right">
                      {{ (player.balance ? player.balance : 0) + (player.bonus_balance ? player.bonus_balance : 0) }} {{ player.currency }}
                    </v-col>
                  </v-row>
          
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $i18n.t('cashout_balance') }}:
                    </v-col>
                    <v-col class="text-right">
                      
                      {{ player.balance && player.bonus_balance && player.wager_amount === '0' ? player.bonus_balance - player.balance : 0 }} {{ player.currency }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="text-left">
                      {{ $t('bonus_balance') }}:
                    </v-col>
                    <v-col class="text-right">
      
                      {{ player.bonus_balance ? player.bonus_balance.toLocaleString() : 0 }} {{ player.currency }}
                    </v-col>
                  </v-row>
                    <v-row align="center">
                      <v-col class="text-left">
                        {{ $i18n.t('wager_playthrough') }}:
                      </v-col>
                      <v-col class="justify-end d-flex align-center">
                        <span class="d-flex align-center">
                          <span>{{ player.wagering
                            && player.wagering !== null ? player.wagering : 0 }}</span>
                          <span class="ml-1">{{ player.currency }}</span>
      
                        </span>
                        <span class="mx-1">/</span>
                        <span class="d-flex align-center">
                          <span>{{ player && player.wager_amount !== null ? player.wager_amount : 0 }}</span>
                          <span class="ml-1">{{ player.currency }}</span>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col class="text-left">
                        {{ $i18n.t('deposits') }}:
                      </v-col>
                      <v-col class="text-right" style="color: var(--green-success)">
                        {{ !player.withdraws || player.deposits === null ? 0 : player.deposits }} {{ player.currency }}
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col class="text-left">
                        {{ $i18n.t('withdrawals') }}:
                      </v-col>
                      <v-col class="text-right" style="color: var(--red)">
                        {{ !player.withdraws || player.withdraws === null ? 0 :  player.withdraws }} {{ player.currency }}
                        
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col class="text-left">
                        {{ $i18n.t('verif_status') }}:
                      </v-col>
                      <v-col class="text-right" style="color: var(--red)">
                      </v-col>
                    </v-row>
                </template>
              </Card>
            </v-col>
            <v-col v-if="transaction.status !== 1">
              <v-spacer></v-spacer>
            <div>
              <Button :label="$t('decline')"
              icon="pi pi-ban" class="p-button-sm p-button-danger" />
              <Button :label="$t('approve')"
                          icon="pi pi-save" class="p-button-sm p-button-success ml-3" />
            </div>
          </v-col>
          </v-row>
        </template>
      </Card>
    </div>
    </v-main>
  </template>
  
  <script>
  import {mapGetters} from "vuex";
  
  
  export default {
    name: "PlayerTransaction",
    components: {},
    props: {
      playerId: {
        required: true,
        default: null
      },
      paymentId: {
        required: true,
        default: null
      }
    },
    data() {
      return {
        player: {},
        //transaction:{},
        loading: true,
      }
    },
    beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'player_transaction')
    });
    },
    async mounted() {
        await this.$store.dispatch('getOnePayment', {payId: this.paymentId});
        this.player = await this.$store.dispatch('playerInfo', {id: this.playerId});
        this.loading = false;
    },
    computed: {
      ...mapGetters({
        transaction: 'getSelectedPayment'
      }),
      translatedType() {
      switch (this.transaction.payment_type) {
        case 'deposit':
          return this.$t('deposit');
        case 'withdraw':
          return this.$t('withdraw');
        default:
          return this.transaction.payment_type;
      }
    },
      translatedPaymentType() {
        if (this.transaction && this.transaction !== null) {
          
        
      switch (this.transaction.payment_sub_type) {
        case 'auto':
          return this.$t('auto');
        case 'manual':
          return this.$t('manual');
        default:
          return this.transaction.payment_sub_type;
      }
    }
    },
    transactionDate() {
      const dateObject = new Date(this.transaction.date);

      if (!isNaN(dateObject.getTime())) {
        return dateObject.toLocaleString();
      } else {
        return 'Invalid Date';
      }
    },
    transactionStatus() {
      
      switch (this.transaction.status) {
        
        case 0:
          return this.$t('processing')
        case 1:
          return this.$i18n.t('completed');
        case 2:
          return this.$i18n.t('processing_with_fee');
        case 3:
          return  this.$i18n.t('canceled');
      }
      return this.transaction.status;
    },
    statusLabelColor() {
      switch (this.transaction.status) {
        case 0:
          return '';
        case 1:
          return 'var(--green-success)';
        case 2:
          return 'var(--yellow-success)';
        case 3:
          return  'var(--red)';
      }
      return 'var(--green-success)';
    },
    },
    
  
  }
  </script>
  
  <style lang="scss" scoped>
  .custom-overlay{
    background-color: rgb(255 255 255 / 0.3)!important;
        backdrop-filter: blur(10px);
  }
  .header{
    @media (max-width:600px){
        flex-direction: column;
    }
  }
  .column-title{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    text-align: center;
    @media (max-width:950px){
        flex-direction: column;
    }
    &__item{
     flex: 0 1 33.333%;
     &:first-child{
      justify-content: flex-start;
      display: flex;
     }
     &:last-child{
      display: flex;
      align-items: center;
      justify-content: flex-end;
     }
    }
  }
  </style>
  